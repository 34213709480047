import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import showdown from 'showdown'

export const StyretPageTemplate = ({ title, page_name, description, image, content }) => {
  const converter = new showdown.Converter();
  const backgroundImage = typeof image === 'string' ? image : image.childImageSharp.fluid.src

   const renderFooterAction = page_name => {
    const pages = {
      home: {
        display: 'Hjem',
        next: '/stiftelsen'
      },
      stiftelsen: {
        display: 'Stiftelsen',
        next: '/styret',
        prev: '/'
      },
      styret: {
        display: 'Styret',
        next: '/formal',
        prev: '/stiftelsen'
      },
      formal: {
        display: 'Formål',
        next: '/fn',
        prev: '/styret'
      },
      fn: {
        display: 'FNs bærekraftsmål',
        next: '/vedtekter',
        prev: '/formal'
      },
      vedtekter: {
        display: 'Vedtekter',
        next: '/samarbeidspartnere',
        prev: '/fn'
      },
      samarbeidspartnere: {
        display: 'Samarbeidspartnere',
        next: '/stiftelsen',
        prev: '/vedtekter'
      },
    }

    const page = pages[page_name];

    return (
      <div className='footer-action'>
        <Link to={page.prev}  disabled={page.prev == undefined}  className={ `prev ${ page.prev == undefined ? 'disabled' : '' }` } >
          {"<  "}
        </Link>

        <span>{ page.display }</span>

        <Link to={page.next} disabled={page.next == undefined}  className={ `next ${ page.next == undefined ? 'disabled' : '' }` }>
          {"  >"}
        </Link>
      </div>
    )
  }

  return (
  <main id="index" className="foundation" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
    </Helmet>
    <Navbar page_name={page_name}/>

    <section className="hero is-fullheight">
      <div className="mt-12">
        <div className="container main-section">
          <div className="columns">
            <div className="column">
            </div>

            <div className="column is-three-quarters">
                <div className="content has-text-white">
                <h1>Styret</h1>

                <h2>Stiftelsens styre velger samarbeidspartnere og bestemmer støttebeløp.<br></br> 
                Styret har ansvar for kapitalforvaltningen, herunder investeringsstrategien <br></br>
                som skal være tilpasset formålet med forvaltningen. 

                </h2>

                <h2 style={{marginBottom: '30px'}}>Styrets medlemmer er:</h2>

                <p className="board-member"><span className="grayColor">Åge Lærdal</span> - Tidligere overlege ved barneavdelingen, Stavanger Universitetssykehus (SUS)</p>
                <p className="board-member"><span className="grayColor">Ove Jølbo</span> - Direktør i Lyse</p>
                <p className="board-member"><span className="grayColor">Bente Irene Lærdal</span> - Tidligere kreftsykepleier for barn </p>
                <p className="board-member"><span className="grayColor">Ida Jaarvik Hetland</span> - Rådgiver i Innovasjon Norge</p>
                <p className="board-member"><span className="grayColor">Gunn Aadland</span> - Overlege ved Barneavdelingen, Stavanger Universitetssykehus (SUS)</p>
                <p className="board-member"><span className="grayColor">Ivar Walde</span> - Tidligere rektor ved Stavanger Katedralskole</p>

                <p style={{fontSize: '14px', marginTop: '25px'}}>Varamedlem til styret <span className="grayColor">Jonathan Lærdal</span></p>

                <p>SSM skal ikke drive egen operativ forvaltning, men benytter en investeringsrådgiver til å gjennomføre<br></br> 
                    den løpende forvaltningen.
                    Dette påvirker ikke ansvarsforholdene i forvaltningen.</p>                
              </div>
            </div>

            <div className="column">
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer>
      {renderFooterAction(page_name)}
    </footer>
  </main>
  )
}

StyretPageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  page_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

const StyretPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <StyretPageTemplate
        page_name={post.frontmatter.page_name}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        content={post.frontmatter.content}
      />
    </Layout>
  )
}

StyretPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StyretPage

export const StyretPageQuery = graphql`
  query StyretPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        page_name
        title
        description
        content
      }
    }
  }
`
